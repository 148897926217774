<template>
    <div class="nextStep">
        <van-nav-bar left-arrow left-text="访客申请" @click-left="onClickLeft" title="申请详情" />
        <!-- <p>{{rulesForm.name}}</p> -->
        <div class="formModular">
            <div class="formFrame">
                <van-form ref="formData" @failed="onFailed" :label-width="80" @submit="onSubmit">
                    <van-field
                        center
                        required
                        v-model="formData.purpose"
                        name="purpose"
                        label="来访事由"
                        :placeholder="formData.purpose"
                        :rules="rulesForm.purpose"
                    />

                    <van-field
                        readonly
                        clickable
                        center
                        required
                        v-model="formData.userName"
                        name="userName"
                        label="被访人"
                        :placeholder="formData.userName"
                        :rules="rulesForm.userName"
                        right-icon="arrow"
                        @click="switchView"
                    />

                    <van-field
                        readonly
                        required
                        clickable
                        :rules="rulesForm.camouflageStartTime"
                        name="camouflageStartTime"
                        :value="formData.camouflageStartTime"
                        label="开始时间"
                        @click="openDatetimePicker('选择开始时间',(tag.index == 2))"
                        :disabled="tag.index == 2"
                    />
                    <!-- placeholder="点击选择时间" -->

                    <van-field class="duration" label="来访时长">
                        <template #input>
                            <ul class="ul">
                                <li
                                    v-for="(item,index) in tag.list"
                                    :key="index"
                                    class="li"
                                    :class="item.checked ? 'hit' : ''"
                                    @click="tagRadioClick(index)"
                                >{{item.text}}</li>
                            </ul>
                        </template>
                    </van-field>

                    <van-field
                        readonly
                        required
                        clickable
                        :rules="rulesForm.camouflageEndTime"
                        name="camouflageEndTime"
                        :value="formData.camouflageEndTime"
                        label="结束时间"
                        @click="openDatetimePicker('选择结束时间',(tag.index != 3))"
                        :disabled="tag.index != 3"
                    />
                    <!-- placeholder="点击选择时间" -->

                    <van-popup v-model="time.showPicker" position="bottom">
                        <van-datetime-picker
                            v-model="time.currentDate"
                            type="datetime"
                            :title="time.title"
                            :min-date="time.startMinDate"
                            :max-date="time.startMaxDate"
                            :formatter="formatter"
                            @confirm="timeConfirm"
                            :filter="filter"
                        />

                        <!-- <van-datetime-picker
                            v-model="time.currentDate"
                            type="datetime"
                            :title="time.title"
                            :min-date="time.startMinDate"
                            :max-date="time.startMaxDate"
                            :formatter="formatter"
                            @confirm="timeConfirm"
                        />-->
                    </van-popup>

                    <van-field
                        v-model="formData.remarks"
                        rows="2"
                        autosize
                        label="备 注"
                        type="textarea"
                        maxlength="50"
                        placeholder="请输入备注"
                        show-word-limit
                    />

                    <div style="margin: 16px;">
                        <van-button
                            round
                            block
                            type="info"
                            :loading="submitBtnLoading"
                            native-type="submit"
                            loading-text="表单提交中..."
                        >提交</van-button>
                    </div>
                </van-form>
            </div>
        </div>

        <!-- <searchComponents /> -->
    </div>
</template>


<script>
    import { get,post } from "../../utils/request";
    import {Toast} from 'vant';
	import { Dialog } from 'vant';

	// import  searchComponents from "../searchPage/searchComponents";

    export default {
        name: "nextStep",
        components: {
			// searchComponents
        },
        data() {
            return {

                formData:{
                    purpose:'',
                    base64Image:null,

					startTime:this.timeRound('timestamp',new Date()),//开始时间
					endTime:this.timeRound('timestamp',new Date()) + 3600000 * 4,//结束时间

					camouflageStartTime:null,//开始时间
					camouflageEndTime:null,//结束时间
                },
                rulesForm:{
                    purpose:[
                        { required: true, message: '请填写来访事由' }
                    ],
					userName:[
                        { required: true, message: '请选择被访人' }
                    ],
                    mobile:[
                        { required: true, message: '请填手机号' },
                        {
                            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
                            message: '手机格式错误'
                        }
                    ],
                    code:[
                        { required: true, message: '请填验证码' },
                        { pattern: /^\d{6}$/, message: '验证码格式错误' }
                    ],
					camouflageStartTime:[
						{ required: true, message: '请填写结束时间' }
					],
					camouflageEndTime:[
						{ required: true, message: '请填写开始时间' }
					]
                },
				//时长标签
				tag:{
					list:[
						{
							text:'30分钟',
							checked: false
						},
						{
							text:'4小时',
							checked: true
						},
						{
							text:'当天',
							checked: false
						},
						{
							text:'自定义',
							checked: false
						}
					],
					index:1,
				},
				
				//选择时间弹窗的数据
				time:{
					showPicker:false,
					startMinDate: new Date(2020, 0, 1),//最小时间
					startMaxDate: new Date(2025, 10, 1),//最大时间
					// currentDate: this.timeRound('timestamp',new Date()),//默认时间
					currentDate: this.timeRound('timestamp',new Date()),//默认时间
					title:'选择开始时间',
				},

				submitBtnLoading:false,
            };
        },
        watch: {
            
        },
        mounted() {
            // console.log('几次');
            // document.title = "丽泽智能科技访客";
			
			// this.formData = this.$store.state.visitorApplyData.formData;

			console.log('$$$$$$$$$$store',this.$store.state.nextStepData,this.$store.state.nextStepData.formData,this.$store.state.visitorApplyData.formData)

			if(this.$store.state.visitorApplyData.formData == null){
                // this.$router.push({
                //     name: "visitor-apply",
                // });
            }

			if(this.$store.state.nextStepData.formData != null){
				console.log('1')
				// console.log('$store',this.$store.state.nextStepData,this.$store.state.nextStepData.formData)
				this.formData = this.$store.state.nextStepData.formData;
				this.tag = this.$store.state.nextStepData.tag;
				this.formData.userName = this.$store.state.visitorApplyData.formData.userName;
			}else{
				
				let date = this.timeRound('timestamp',new Date());
				this.formData.camouflageStartTime = this.dateTimeStr(date);
				this.formData.camouflageEndTime = this.dateTimeStr(date + 3600000 * 4);
				console.log('222222222222',this.time.currentDate)
			}

			// let now = new Date()
			// let step = 15 * 60 * 1000 //十五分钟
			// let mod = now.getTime() % step //当前距离距离上一个15分钟过了多久
			// now.setTime(now.getTime()-mod+step)
			// console.log(now)

		},
        methods: {
			//弹出时间组件格式化/15分钟
			filter(type, options) {
				if (type === 'minute') {
					return options.filter((option) => option % 15 === 0);
				}
				return options;
			},

			//取整时间
			timeRound(type,now){
				let step = 15 * 60 * 1000 //十五分钟
				let mod = now.getTime() % step //当前距离距离上一个15分钟过了多久
				now.setTime(now.getTime()-mod+step)

				let date = Date.parse(now)
				// console.log('取整时间',date,this.dateTimeStr(date));
				if(type == 'timestamp'){
					return date; //返回时间戳
				}else{
					return now;
				}
			},



			//返回上一页
			onClickLeft(){
				Dialog.confirm({
					message: '是否先保存编辑内容？',
					confirmButtonText:'保存',
					cancelButtonText:'放弃',
				})
				.then(() => {
					// on confirm
					this.$store.state.nextStepData.formData = this.formData;
					this.$store.state.nextStepData.tag = this.tag;

					this.$router.push({
						name: "visitor-apply",
					});
				})
				.catch(() => {
					// on cancel
					this.$store.state.nextStepData.formData = null;
					this.$store.state.nextStepData.tag = null;

					this.$router.push({
						name: "visitor-apply",
					});
				});

            },

			//格式化时间选择插件
			formatter(type, value) {
				if (type === 'year') {
					return `${value}年`
				}
				if (type === 'month') {
					return `${value}月`
				}
				if (type === 'day') {
					return `${value}日`
				}
				if(type == 'hour'){
					return `${value}时`
				}
				if(type == 'minute'){
					return `${value}分`
				}
				return value
			},

			//时长标签的选中事件
			tagRadioClick(name){
				console.log('切换了标签',name,this.formData.startTime,this.dateTimeStr(this.formData.startTime))
				
				this.tag.list.map((item, index) => {
					item.checked = index === name ? true : false
				})

				if(this.tag.index != name){
					this.tag.index = name;
					if(name == 3){
						this.dateDisabled = false;
						this.time.currentDate = this.formData.startTime;
					}else if(name == 0){
						// let date = Date.parse(new Date())
						this.formData.camouflageStartTime = this.dateTimeStr(this.formData.startTime);
						this.formData.camouflageEndTime = this.dateTimeStr(this.formData.startTime + 3600000 / 2);

						// this.formData.startTime = date;
						this.formData.endTime = this.formData.startTime + 3600000 / 2;
						this.dateDisabled = true;
					}else if(name == 1){
						// let date = Date.parse(new Date())
						this.formData.camouflageStartTime = this.dateTimeStr(this.formData.startTime);
						this.formData.camouflageEndTime = this.dateTimeStr(this.formData.startTime + 3600000 * 4);

						// this.formData.startTime = date;
						this.formData.endTime = this.formData.startTime + 3600000 * 4;
						this.dateDisabled = true;
					}else if(name == 2){
						// this.formData.camouflageStartTime = this.dateTimeStr(new Date(new Date().toLocaleDateString()).getTime());
						
						// new Date(this.formData.startTime).toDateString() === new Date().toDateString();
						
						// this.formData.camouflageEndTime = this.dateTimeStr(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
						// this.formData.endTime = new Date(new Date().toLocaleDateString()).getTime() + 86400000;

						this.formData.camouflageEndTime = this.dateTimeStr(new Date(new Date(this.formData.startTime).toLocaleDateString()).getTime() + 86400000);
						this.formData.endTime = new Date(new Date(this.formData.startTime).toLocaleDateString()).getTime() + 86400000;

						// console.log('当天,是不是超过一天',(new Date(this.formData.startTime).toDateString() === new Date().toDateString()))
						this.dateDisabled = true;
					}
				}
			},

			//打开时间选择器
			openDatetimePicker(title,value){
				// console.log('打开时间选择器',this.formData.endTime,this.toUTCtime(this.formData.endTime),value);

				if(!value){
					this.time.title = title;
					if(title == '选择开始时间'){
						this.time.currentDate = new Date(this.formData.startTime);
						this.time.startMinDate = this.timeRound('utc',new Date())
					}else{
						this.time.currentDate = new Date(this.formData.endTime);
						this.time.startMinDate = new Date(this.formData.startTime + 60000);
					}
					this.time.showPicker = true;
				}
			},

			//确认时间
			timeConfirm(value){

				// console.log('确认时间',value,this.time.currentDate,Date.parse(this.time.currentDate));
				let date = Date.parse(this.time.currentDate);

				if(this.time.title == '选择开始时间'){
					this.formData.camouflageStartTime = this.dateTimeStr(this.time.currentDate);
					this.formData.startTime = Date.parse(this.time.currentDate);

					if(this.tag.index == 0){
						this.formData.camouflageEndTime = this.dateTimeStr(date + 3600000 / 2);
						this.formData.endTime = date + 3600000 / 2;
					}else if(this.tag.index == 1){
						this.formData.camouflageEndTime = this.dateTimeStr(date + 3600000 * 4);
						this.formData.endTime = date + 3600000 * 4;
					}else if(this.tag.index == 3){
						this.formData.camouflageEndTime = null;
						this.formData.endTime = null;
					}
				}else{
					this.formData.camouflageEndTime = this.dateTimeStr(this.time.currentDate);
					this.formData.endTime = Date.parse(this.time.currentDate);
				}

				// console.log('确认时间',this.time.currentDate,Date.parse(this.time.currentDate));
				this.time.showPicker = false;
			},

			//切换视图
            switchView(){

				// this.$store.state.visitorApplyData.formData = {...this.formData,...this.$store.state.visitorApplyData.formData}//后面会覆盖掉前面的
                this.$store.state.nextStepData.formData = this.formData;
				this.$store.state.nextStepData.tag = this.tag;
				console.log('切换视图',this.$store.state.nextStepData.formData);
				// debugger
				this.$router.push({
                    name: "search-page",
                });
            },

            // 验证通过提交表单
            onSubmit(){
                // console.log('验证通过提交表单',this.formData);
				this.formData.visitorOpenId = localStorage.getItem('openId');
				this.formData.organizationId = localStorage.getItem('organizationId');
				this.submitBtnLoading = true;
				// this.formData.imagSrc = null;


				// this.formData.visitorName = this.formData.name;

				let obj = {...this.$store.state.visitorApplyData.formData,...this.formData};
				obj.imagSrc = null,
				obj.visitorName = obj.name;

				// console.log('验证通过提交表单',this.dateTimeStr(obj.startTime),this.dateTimeStr(obj.endTime));

				post("/api/visitor/new-visiting-application",obj).then((ren) => {
					console.log('验证通过提交表单',ren);
					if (ren.data.code == "200") {
						this.submitBtnLoading = false;

						this.$store.state.successPageData = {
							title: '提交成功',
							explain: '请等待被访人审核',
							router: 'my-profile',
						}

						this.$store.state.nextStepData.formData = null;
						this.$store.state.nextStepData.tag = null;

						this.$store.state.visitorApplyData.formData = null;

						this.$router.push({
							name: "success-page",
						});

					} else {
						Toast.fail(ren.data.message);
						this.submitBtnLoading = false;
					}
				})
				.catch((err) => {
					this.submitBtnLoading = false;
					console.log(err);
				});
            },
            //验证不通过
            onFailed(){
                console.log('验证不通过',this.formData);
            },

        },
		//组件将要被销毁执行的什么周期函数
		beforeDestroy() {
			console.log('组件将要被销毁执行的什么周期函数',this.$route.name);

			// this.$store.state.nextStepData.formData = this.formData;
			// this.$store.state.nextStepData.tag = this.tag;
		}
    };
</script>


<style scoped lang="scss">
.nextStep {
    .formModular {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        .formFrame {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            width: 95%;
        }
        .duration {
            .ul {
                width: 100%;
                display: flex;
                justify-content: space-around;
                .li {
                    &.hit {
                        color: #fff;
                        background-color: #398ade;
                        border: 1px solid #398ade;
                    }
                    display: inline-block;
                    padding: 0 5px;
                    border: 1px solid #eeeeee;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>
